<template>
  <div>
    <div class="mb-1">
      <page-top />
    </div>
    <router-view />
  </div>
</template>

<script>
import PageTop from '../components/view-top/PageTop.vue'

export default {
  name: 'OrderView',
  components: {
    PageTop,
  },
}
</script>
